@import "../../../node_modules/normalize.css/normalize";

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 10px;
  overflow-x: hidden;
  max-width: 100vw;
  font-family: var(--font-family-main);
}

body {
  margin: 0;
  overflow-x: hidden;
  font-size: var(--font-size);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: currentColor;
}

// ul,
// ol,
// p {
//   padding: 0;
//   margin: 0;
// }

.content_area {
  max-width: var(--max-width);
  margin: 0 auto;
  width: 100%;
}

.mg-top-1 {
  margin-top: 1rem;
}

.mg-top-2 {
  margin-top: 2rem;
}

.mg-top-3 {
  margin-top: 3rem;
}

.mg-top-4 {
  margin-top: 4rem;
}

.section-margin {
  margin-top: var(--section-margin);
}

.section-padding {
  padding-top: var(--section-margin);
}

.row_flex {
  display: flex;
  &-column {
    flex-direction: column;
  }
}

.row_grid {
  display: grid;
}
// .row {
//   display: flex;
//   flex-wrap: wrap;
// }

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.no-margin {
  margin: unset;
}

@include notebook {
  .content_area {
    max-width: var(--max-width);
  }
}

@include tablet {
  .content_area {
    max-width: var(--max-width);
  }
}

@include mobile {
  .content_area {
    max-width: var(--max-width);
  }
}
