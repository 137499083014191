.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  width: 80rem;
  height: auto;
  z-index: 200;
  transition: all 0.3s ease-out;
  transform: translateX(-50%) translateY(calc(-50% + 15rem));
  opacity: 0;
  visibility: hidden;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  overflow: auto;

  &.is-active {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 150;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-out;
    backdrop-filter: blur(6px);
    &.is-active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__close {
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    background: url('../img/icons/ic_modal_close.svg') no-repeat center;
    position: absolute;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    background-size: contain;
    &:hover {
      opacity: .8;
    }
  }

  &__wrapper {
    padding: 4rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
  }

  &__header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {

  }

}