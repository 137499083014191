// Headers
h1,
.h1 {
  font-family: var(--font-family-headers);
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-size: 4.8rem;
}

h2,
.h2 {
  font-family: var(--font-family-headers);
  font-weight: 300;
  text-transform: uppercase;
  font-size: 3.4rem;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  letter-spacing: 0.1rem;

  @include mobile {
    font-size: 3rem;
  }

  @media (max-width: 414px) {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 0px;
  }
}

p {
  line-height: 1.4;
  margin-bottom: 1rem;
  font-size: var(--font-size);
  margin-top: 0;
}