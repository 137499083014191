/* Переменные для медиа запросов */
$mobileBreakpoint: 640px;
$tabletBreakpoint: 900px;
$smallDesktopBreakpoint: 1200px;
$notebooksBreakpoint: 1600px;


/* Ширина контента */
:root {
  --block-padding: 13.5vw;
	--max-width: calc(100vw - 2 * 6.25vw);
}
@media screen and (max-width: $notebooksBreakpoint) {
  :root {
    --block-padding: 6vw;
		--max-width: calc(100vw - 2 * 6vw);
  }
}
@media screen and (max-width: $smallDesktopBreakpoint) {
  :root {
    --block-padding: 4rem;
		--max-width: calc(100vw - 2 * 4rem);
  }
}
@media screen and (max-width: $mobileBreakpoint) {
  :root {
    --block-padding: 2rem;
		--max-width: calc(100vw - 2 * 1.6rem);
  }
}

// Margin
:root {
  --section-margin: 12rem;
  
  @media screen and (max-width: $tabletBreakpoint) {
    --section-margin: 6rem;
  }
  
  @media screen and (max-width: $mobileBreakpoint) {
    --section-margin: 4rem;
  }
}

:root {
  // Цвета
  --color-primary: #000000;
  --color-secondary: #575757;

  // Шрифты и типографика
  --font-family-headers: "Open Sans", Arial, sans-serif;
  --font-family-main: "PT Sans", Arial, sans-serif;

  --font-size: 1.6rem;

  // Дополнительные переменные вносим ниже
  
}

