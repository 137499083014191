.field {
    &__wrap {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        input, select, textarea {
            font-size: 1.6rem;
            padding: 0.4rem 1rem;
        }
    }

    &__label {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__title {
        font-size: 1.6rem;
        padding-left: 1rem;
    }

    &__description {
        font-size: 1.2rem;
        padding-left: 1rem;
        font-weight: 400;
        color: #696969;
    }

    &__radio,
    &__checkbox {
        line-height: 1;

        >input {
            position: absolute;
            z-index: -1;
            opacity: 0;
            height: 0;
            display: table;

            &:not(:disabled):not(:checked)+.field__title:hover::before {
                border-color: var(--color-secondary);
            }

            &:not(:disabled):active+.field__title::before {
                background-color: var(--color-secondary);
                border-color: var(--color-secondary);
            }

            &:focus:not(:checked)+.field__title::before {
                border-color: var(--color-primary);
            }

            &:disabled+.field__title::before {
                background-color: #e9ecef;
            }
        }

        .field__title {
            user-select: none;
            line-height: 1.2;
            position: relative;
            cursor: pointer;
            padding-left: 2.6rem;

            a {
                text-decoration: underline;
            }

            &::before {
                content: '';
                display: inline-block;
                width: 1.8rem;
                height: 1.8rem;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid var(--color-secondary);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
                position: absolute;
                left: 0;
            }
        }
    }

    &__radio {
        >input {
            &:checked+.field__title::before {
                border-color: var(--color-primary);
                background-color: var(--color-primary);
            }
        }

        .field__title {
            &::before {
                border-radius: 2rem;
            }
        }
    }

    &__checkbox {
        >input {
            &:checked+.field__title::before {
                border-color: var(--color-primary);
                background-color: var(--color-primary);
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
            }
        }

        .field__title {
            &::before {
                border-radius: 0.4rem;
            }
        }
    }

    @include tablet {
        &__title {
            font-size: 1.6rem;
        }

        &__radio,
        &__checkbox {
            .field__title {
                font-size: 1.4rem;
                padding-left: 2.8rem;

                &:before {
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }
        }
    }

    @include mobile {
        &__radio {
            .field__title {
                text-align: center;
                font-size: 1.2rem;
                padding-left: 0;

                &:before {
                    width: 2rem;
                    height: 2rem;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, -110%);
                }
            }
        }

        &__checkbox {
            .field__title {
                font-size: 1.2rem;

                &:before {
                    width: 2rem;
                    height: 2rem;
                    top: 0.2rem;
                }
            }
        }
    }

}