:root {
    --ui-color-primary: #333333;
    --ui-color-secondary: #c3c3c3;
    --ui-block-padding: 1.4rem;
    --ui-area-gap: 1rem;
}

.ui {
    &_area {
        display: flex;
        flex-wrap: wrap;
        gap: var(--ui-area-gap);
    }
    &_block {
        border: 1px dashed var(--ui-color-primary);
        border-radius: 10px;
        padding: var(--ui-block-padding);
        &-lg_3 {
            flex: 0 0 calc(33% - var(--ui-area-gap));
            width: calc(33% - var(--ui-area-gap));
            max-width: calc(33% - var(--ui-area-gap));
        }
        &-md_6 {
            @include tablet {
                flex: 0 0 calc(50% - var(--ui-area-gap));
                width: calc(50% - var(--ui-area-gap));
                max-width: calc(50% - var(--ui-area-gap));
            }
        }
        &-sm_12 {
            @include mobile {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
            }
        }
        &.row_flex {
            gap: 1rem;
        }
    }
    &_color {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        &_square {
            width: 10rem;
            height: 5rem;
            border: 1px solid black;
            position: relative;
            margin-bottom: 0.5rem;
        }
        &_title {
            span {
                font-size: 2rem;
                display: table;
                margin-top: 0.5rem;
            }
        }
    }
}

.dev_panel {
    position: fixed;
    background-color: #382A76;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    width: 250px;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 20px;
    color: white;
    box-shadow: #00000080 0px 0px 0px 0px;
    transition: .4s;
    &-opened {
        transform: translateX(0%);
        box-shadow: #00000080 6px 0px 14px 4px;
    }
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        display: none;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1050;
        backdrop-filter: blur(2px);
        &-opened {
            display: block;
        }
    }
    &__header {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: flex-start;
        padding-left: 10px;
    }
    &__logo {
        img {
            width: 100%;
            max-width: 150px;
            display: block;
            margin: 0 auto;
            padding-top: 5px;
        }
    }
    &__btn {
        display: block;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 28px;
        transform: translateX(100%);
        z-index: 100;
        background-image: url('../img/ui_kit/arrow_left.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-color: #372c74;
        background-size: 40%;
        transform: translateX(75%) rotate(90deg) scaleY(1);
        width: 40px;
        height: 24px;
        &-opened {
            transform: translateX(75%) rotate(90deg) scaleY(-1);
        }
    }
    &__menu {
        font-size: 15px;
        ul {
            list-style: square;
            line-height: 1.5;
            padding-left: 25px;
            margin-bottom: 5px;
            li {
                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}